import { useRef } from "react";
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion";

import OneStepImage from '../../../assets/one_step_reg.png';
import FaceIdImage from '../../../assets/face_id_clip.jpg';

const OneStepRegSection = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });

    const rotate = useTransform(scrollYProgress, [0, 1], [0, -20]);
    const fontTranslateY = useTransform(scrollYProgress, [0, 1], [0, 150]);
    const scale = useTransform(scrollYProgress, [0, 1], [1.1, 1]);
    const translate = useTransform(scrollYProgress, [0, 1], [0, -50]);
    
    return (
        <section className="h-fit sm:h-[75vh] pt-36 sm:pt-0 pb-10 sm:pb-0">
            <div ref={ref} className="grid grid-cols-1 sm:grid-cols-2 justify-items-center sm:p-1 p-10">
                <motion.div 
                    style={{ translateY: fontTranslateY }}
                    className="grid grid-cols-1 gap-5 content-center"
                >
                    <h1 className="leading-none font-gotham text-white text-3xl"><span className="text-green-200">ONE STEP</span> wallet creation</h1>
                    <h1 className="font-gotham text-white text-3xl">Secured with your passkey</h1>
                    <motion.div 
                        style={{ rotate: rotate }}
                        className="grid grid-cols-1"
                    >
                        <h1 className="font-apple text-green-200 text-9xl">{String.fromCodePoint(1054769)}</h1>
                    </motion.div>
                </motion.div>
                <div 
                    className="sm:hidden h-48"
                >
                </div>
                <div 
                    className=""
                >
                    <div className="relative z-0">
                        <motion.div 
                            style={{ scale }} 
                            className="h-[60vh] pl-16 pt-7"
                        >
                            <img src={OneStepImage} alt="One step registration page" className="h-[60vh] rounded-xl"/>
                        </motion.div>
                        <motion.div 
                            style={{ translateX: translate }} 
                            className="absolute inset-0 flex z-10"
                        >
                            <img src={FaceIdImage} alt="Face ID prompt for passkey registration" className="h-[20vh] rounded-xl"/>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OneStepRegSection;