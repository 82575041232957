import { useRef } from "react";
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion";

import HomePageScreenshot from '../../../assets/trimmed_homepage.jpg'

const SelfCustodySection = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });

    const scale = useTransform(scrollYProgress, [0, 1], [1.1, 1]);
    
    return (
        <section className="h-fit sm:h-[75vh] sm:py-16 sm:px-4">
            <div ref={ref} className="grid grid-cols-1 sm:grid-cols-2 justify-items-center gap-10 p-10 sm:gap-4">
                <motion.div 
                    style={{ scale: scale }}
                    className="sm:hidden grid content-center gap-3 bg-cover"
                >
                    <h1 className="font-gotham text-white text-3xl">Self custody, <span className="text-green-200">SIMPLIFIED</span></h1>
                    <h1 className="text-white font-gotham">Equipped with a familiar interface, Oxen Flow makes it easier than ever to manage your assets. We never have access.</h1>
                </motion.div>
                <div>
                    <div className='place-self-center relative group'>
                        <div className='z-0 absolute -inset-3 rounded-2xl bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ... blur opacity-25'/>
                        <img src={HomePageScreenshot} alt="Screenshot of the app homepage" className='z-10 relative h-[60vh] rounded-xl'/>
                    </div>
                </div>
                <motion.div 
                    style={{ scale: scale }}
                    className="hidden sm:grid content-center gap-3 bg-cover"
                >
                    <h1 className="font-gotham text-white text-3xl">Self custody, <span className="text-green-200">SIMPLIFIED</span></h1>
                    <h1 className="text-white font-gotham">Equipped with a familiar interface, Oxen Flow makes it easier than ever to manage your assets.</h1>
                </motion.div>
            </div>
        </section>
    )
}

export default SelfCustodySection;