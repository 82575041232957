import React, { useState } from "react";
import { ReactLenis } from 'lenis/dist/lenis-react';

import Navigation from '../components/navigation/navigation';
import Footer from '../components/footer/footer';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What is Oxen Flow?",
    answer: "Oxen Flow is a mobile application that allows users to manage their digital assets on Flare Network seamlessly, with an intuitive interface for a smooth experience.",
  },
  {
    question: "How do I sign up for Oxen Flow?",
    answer: "You can sign up by downloading our iOS app and tapping on the 'Register' button. Just follow the steps to create your account.",
  },
  {
    question: "Is Oxen Flow Non-Custodial?",
    answer: "Yes, you have complete control over your private keys and assets. You are responsible for keeping this information secure. Oxen Flow does not have the ability to access your private key material.",
  },
  {
    question: "Is my information secure on Oxen Flow?",
    answer: "Yes, we prioritize user security and ensure that your data is protected through advanced encryption and security measures. Oxen Flow does not collect any senstive user information and the email you register with is only used for passkey support. In addition, the Oxen Flow application has been audited by third party.",
  },
  {
    question: "Are there any fees associated with using Oxen Flow?",
    answer: "When sending transactions, blockchain transaction fees are applicable.",
  },
  {
    question: "Who can I contact for support?",
    answer: "You can reach our support team by emailing info@oxenflow.io",
  },
];

const FAQItemComponent: React.FC<{ item: FAQItem }> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="grid grid-cols-1 justify-items-start p-5">
        <div>
            <h1 className="font-gotham text-white text-xl text-left" onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }}>
                {item.question}{isOpen && <span className="pl-4 font-gotham text-white text-2xl text-left">-</span>}{!isOpen && <span className="pl-4 font-gotham text-white text-2xl text-left">+</span>}
            </h1>
        </div>
      {isOpen && <p className="font-gotham text-white text-left pt-2">{item.answer}</p>}
    </div>
  );
};

const SupportPage: React.FC = () => {
  return (
    <ReactLenis root options={{ lerp: 0.1, duration: 1.5 }}>
        <div className="app-container bg-black">
            <div className='grid grid-cols-1 gap-5'>
            <Navigation />
            <h1 className="font-['Gotham-Black'] text-white text-4xl">Frequently Asked Questions</h1>
                <div className="sm:w-2/3 sm:p-10">
                    {faqData.map((item, index) => (
                        <FAQItemComponent key={index} item={item} />
                    ))}
                </div>
            </div>
            <Footer />
            <h1 className='font-gotham text-white p-4'>© Oxen Flow 2024</h1>
        </div>
    </ReactLenis>
  );
};

export default SupportPage;