import { useRef } from "react";
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion";


import FYEOLogo from '../../../assets/fyeo.png';

const SecuritySection = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });

    const fontTranslateY = useTransform(scrollYProgress, [0, 1], [0, 150]);
    
    return (
        <section className="h-fit sm:h-[75vh] sm:-mt-24 sm:pt-0 pb-10 sm:pb-0">
            <div ref={ref} className="grid grid-cols-1 justify-items-center sm:p-0 p-10">
                <motion.div 
                    style={{ translateY: fontTranslateY }}
                    className="grid grid-cols-1 gap-5 content-center w-96"
                >
                    <h1 className="font-apple text-green-200 text-9xl">{String.fromCodePoint(1050521)}</h1>
                    <h1 className="leading-none font-gotham text-white text-3xl"><span className="text-green-200">SECURE</span>, by design.</h1>
                    <h1 className="font-gotham text-white">Oxen Flow is fully audited by FYEO and has been built with data minimization policies in mind.</h1>
                    <div className='place-self-center relative group'>
                        <div className='z-0 absolute -inset-3 rounded-2xl bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ... blur opacity-25'/>
                        <img src={FYEOLogo} alt="FYEO Logo" className='w-40'/>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

export default SecuritySection;