import React, { useRef, useEffect, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useInView
} from "framer-motion";

import OwnYourUsernameVid from '../../../assets/own-your-username-vid.mp4';

const OwnYourUsernameSection = () => {
    const vidRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlayback = () => {
        if (vidRef.current) {
            if (!isPlaying) {
                vidRef.current.play();
                setIsPlaying(true);
            } else {
                vidRef.current.pause();
                setIsPlaying(false);
            }
        }
    }

    const vidInView = useInView(vidRef);

    useEffect(() => {
        if (vidInView) {
            if (!isPlaying) {
                togglePlayback()
            }
        } else {
            if (isPlaying) {
                togglePlayback()
            }
        }
    }, [vidRef, vidInView, isPlaying]);

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });

    const scale = useTransform(scrollYProgress, [0, 1], [1.1, 1]);
    
    return (
        <section className="h-fit sm:h-[75vh] sm:py-16 sm:px-4">
            <div ref={ref} className="grid grid-cols-1 justify-items-center gap-10 p-10 sm:gap-7">
                <motion.div 
                    style={{ scale: scale }}
                    className="grid content-center gap-3 bg-cover"
                >
                    <h1 className="font-gotham text-white text-3xl"><span className="text-green-200">OWN</span> your username</h1>
                    <h1 className="font-gotham text-white sm:w-96">Claim, buy, or list any username that you own. Take control of your digital identity</h1>
                </motion.div>
                <div>
                    <video muted ref={vidRef} className="h-[60vh] rounded-xl">
                        <source src={OwnYourUsernameVid} type="video/mp4"/>
                    </video>
                </div>
            </div>
        </section>
    )
}

export default OwnYourUsernameSection;