import AureusOxLogo from '../../../assets/aureus-ox-logo-cmyk-design-300ppi-02.png'
import FlareTaglineLogo from '../../../assets/Flare_tagline_horizontal_dark_bg.png';


const PoweredBySection = () => {
    return (
        <section className="h-fit sm:h-[75vh] sm:py-16 sm:px-4">
            <div className="grid grid-cols-1 h-full sm:grid-cols-2 justify-items-center gap-10 p-10 sm:gap-4">
                <div className="grid content-center gap-7">
                    <h1 className="font-gotham text-white text-3xl">A Product By:</h1>
                    <h1 className="font-gotham text-white">Built in house by the Aureus Ox development team.</h1>
                    <div className='place-self-center relative group'>
                        <div className='z-0 absolute -inset-3 rounded-2xl bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ... blur opacity-25'/>
                        <img src={AureusOxLogo} alt='Aureus Ox Logo' className='w-48'/>
                    </div>
                </div>
                <div className="grid content-center gap-7">
                    <h1 className="font-gotham text-white text-3xl">Powered By:</h1>
                    <h1 className="font-gotham text-white">Built to scale, Oxen Flow uses the next generation blockchain for a next generation app.</h1>
                    <div className='place-self-center relative group'>
                        <div className='z-0 absolute -inset-3 rounded-2xl bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ... blur opacity-25'/>
                        <img src={FlareTaglineLogo} alt='Flare Logo' className='w-56'/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PoweredBySection;