import { ReactLenis } from 'lenis/dist/lenis-react';

import Navigation from '../components/navigation/navigation';
import Header from '../components/header/header';
import OneStepRegSection from '../components/sections/one-step-reg/one-step-reg';
import SelfCustodySection from '../components/sections/self-custody/self-custody';
import OwnYourUsernameSection from '../components/sections/own-your-username/own-your-username';
import PoweredBySection from '../components/sections/powered-by/powered-by';
import SecuritySection from '../components/sections/security-section/security-section';
import Footer from '../components/footer/footer';


const HomePage = () => {
    return (
        <ReactLenis root options={{ lerp: 0.1, duration: 1.5 }}>
            <div className="app-container bg-black">
                <div className='grid grid-cols-1 gap-10'>
                    <Navigation />
                    <Header />
                    <OneStepRegSection />
                    <SelfCustodySection />
                    <OwnYourUsernameSection />
                    <PoweredBySection />
                    <SecuritySection />
                </div>
                <Footer />
                <h1 className='font-gotham text-white p-4'>© Oxen Flow 2024</h1>
            </div>
        </ReactLenis>
    )
}

export default HomePage;