import { useState } from 'react';
import { motion, useScroll, useMotionValue, useMotionValueEvent } from 'framer-motion';

import oxenFlowHorizontal from '../../assets/0xenFlow_White_HLogo_Letters_Only.png';

const Navigation = () => {
    const { scrollYProgress } = useScroll();
    const inverseScrollYProgress = useMotionValue(1);

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
      inverseScrollYProgress.set(1 - latest)
    })

    const [toggleMenu, setToggleMenu] = useState(false);

    return (
      <nav>
        <div className="max-w-7xl mx-6">
          <div className="flex justify-between w-5/6 ">
            {/* Primary menu and logo */}
            <div className="flex items-center gap-16 mt-5 mb-2">
              {/* logo */}
              <div>
                <a
                  href="/"
                  className="flex gap-1 font-bold text-gray-700 items-center "
                >
                  <img src={oxenFlowHorizontal} alt='Oxen Flow Horizontal Logo' className='w-40'/>
                </a>
              </div>
              {/* primary */}
              {/* <div className="hidden lg:flex gap-8 ">
                <a href="#" className="">
                  Home
                </a>
                <a href="#">Benefits</a>
                <a href="#">Our Classes</a>
                <a href="#">Contact Us</a>
              </div> */}
            </div>
            {/* secondary */}
            <div className="flex gap-6">
              {/* Mobile navigation toggle */}
              <div className="lg:hidden flex items-center">
                <button onClick={() => setToggleMenu(!toggleMenu)}>
                  {/* <Bars3Icon className="h-6" /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* mobile navigation */}
        {/* <div
          className={`fixed z-40 w-full  bg-gray-100 overflow-hidden flex flex-col lg:hidden gap-12  origin-top duration-700 ${
            !toggleMenu ? "h-0" : "h-full"
          }`}
        >
          <div className="px-8">
            <div className="flex flex-col gap-8 font-bold tracking-wider">
              <a href="#" className="border-l-4 border-gray-600">
                Features
              </a>
              <a href="#">Pricing</a>
              <a href="#">Download</a>
              <a href="#">Classic</a>
            </div>
          </div>
        </div> */}
        <motion.div className="px-5 pt-2 pb-4" style={{ scaleX: inverseScrollYProgress }}>
          <div className="h-1 rounded-full bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ..."></div>
        </motion.div>
      </nav>
      );
}

export default Navigation;